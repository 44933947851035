import React, { useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const ImperialCalculator = () => {
  const [feet, setFeet] = useState("");
  const [inches, setInches] = useState("");
  const [fraction, setFraction] = useState("");

  const handleFeetChange = (event) => {
    setFeet(parseInt(event.target.value) || 0);
  };

  const handleInchesChange = (event) => {
    setInches(parseInt(event.target.value) || 0);
  };

  const handleFractionChange = (event) => {
    const value = event.target.value;
    // Allow only numbers, '/', or an empty string
    if (/^\d*\/?\d*$/.test(value)) {
      setFraction(value);
    }
  };

  const convertToMillimeters = () => {
    const feetToMm = feet * 304.8; // 1 foot = 304.8 millimeters
    const inchesToMm = inches * 25.4; // 1 inch = 25.4 millimeters
    let fractionMm = 0;

    // Convert fraction of inches to millimeters
    if (fraction.includes("/")) {
      const [numerator, denominator] = fraction.split("/");
      const fractionValue = parseFloat(numerator) / parseFloat(denominator);
      fractionMm = fractionValue * 25.4; // Convert fraction part to millimeters
    }

    const totalMm = feetToMm + inchesToMm + fractionMm;
    return {
      feetToMm: feetToMm.toFixed(2),
      inchesToMm: inchesToMm.toFixed(2),
      fractionMm: fractionMm.toFixed(2),
      totalMm: totalMm.toFixed(2),
    };
  };

  const { feetToMm, inchesToMm, fractionMm, totalMm } = convertToMillimeters();

  return (
    <Container maxWidth="lg" sx={{ marginTop: 4 }}>
      <Paper elevation={3} sx={{ padding: 2, borderRadius: 8 }}>
        <Typography
          gutterBottom
          sx={{ fontWeight: "bold", fontSize: "14px", marginTop: 4 }}
        >
          Enter feet
        </Typography>
        <TextField
          label=""
          variant="outlined"
          fullWidth
          margin="normal"
          type="number"
          placeholder="2"
          value={feet}
          onChange={handleFeetChange}
          InputProps={{
            inputProps: { min: 0 },
            style: { height: "40px", padding: "8px" }, // Adjust padding as needed
          }}
          InputLabelProps={{
            style: { lineHeight: "40px" }, // Adjust line-height to vertically center the label text
          }}
        />
        <Typography
          gutterBottom
          sx={{ fontWeight: "bold", fontSize: "14px", marginTop: 1 }}
        >
          Enter inches
        </Typography>
        <TextField
          label=""
          variant="outlined"
          fullWidth
          margin="normal"
          type="number"
          placeholder="3"
          value={inches}
          onChange={handleInchesChange}
          InputProps={{
            inputProps: { min: 0 },
            style: { height: "40px", padding: "8px" }, // Adjust padding as needed
          }}
          InputLabelProps={{
            style: { lineHeight: "40px" }, // Adjust line-height to vertically center the label text
          }}
        />
        <Typography
          gutterBottom
          sx={{ fontWeight: "bold", fontSize: "14px", marginTop: 1 }}
        >
          Enter fraction of inches
        </Typography>
        <TextField
          label=""
          variant="outlined"
          fullWidth
          margin="normal"
          type="text"
          placeholder="3/5"
          value={fraction}
          onChange={handleFractionChange}
          InputProps={{
            inputProps: { min: 0 },
            style: { height: "40px", padding: "8px" }, // Adjust padding as needed
          }}
          InputLabelProps={{
            style: { lineHeight: "40px" }, // Adjust line-height to vertically center the label text
          }}
        />
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>FT TO MM</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>INCHES TO MM</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  FRACTION OF INCHES TO MM
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>TOTAL (MM)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{feetToMm}</TableCell>
                <TableCell>{inchesToMm}</TableCell>
                <TableCell>{fractionMm}</TableCell>
                <TableCell sx={{ backgroundColor: "#90EE90" }}>
                  {totalMm}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default ImperialCalculator;
