// Import required libraries
import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const Home = () => {
  const [pitchAngles, setPitchAngles] = useState([42, 41, 40, 39, 38, 37, 36]);
  const [tangents, setTangents] = useState([]);
  const [fflFflInMM, setFflFflInMM] = useState("");
  const [minGoing, setMinGoing] = useState("220");
  const [spindleSize, setSpindleSize] = useState("");
  const [gap, setGap] = useState("");
  const [maxGoing, setMaxGoing] = useState("");
  const [maxGap, setMaxGap] = useState("");
  const [selectedOption, setSelectedOption] = useState("0");

  const options = [
    { value: "-2", label: "-2" },
    { value: "-1", label: "-1" },
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
  ];

  const calculateTangent = (angle) => {
    return Math.tan(angle * (Math.PI / 180)).toFixed(6);
  };

  const calculateGoingMM = (fflFflInMM, tangent) => {
    if (tangent === 0 || fflFflInMM === "") return "";
    return (fflFflInMM / tangent).toFixed(2);
  };

  const calculateGoingInMM = (goingMM, noOfTreads) => {
    // console.log(goingMM);
    console.log(noOfTreads);

    if (noOfTreads === "" || goingMM === "") return "";
    return (goingMM / noOfTreads).toFixed(2);
  };

  const calculateRiseMM = (fflFflInMM, noOfTreads) => {
    if (noOfTreads === "" || fflFflInMM === "") return "";
    return (fflFflInMM / noOfTreads).toFixed(2);
  };
  const gcd = (a, b) => {
    return b === 0 ? a : gcd(b, a % b);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const convertToFeetInches = (mm) => {
    if (mm === "") {
      return "";
    } else {
      mm = parseFloat(mm);
      if (isNaN(mm)) {
        return "";
      }

      const feet = Math.floor(mm / 304.8);
      const remainingMM = mm % 304.8;
      const inches = remainingMM / 25.4;
      let wholeInches = Math.floor(inches);

      let fractionalInches = ((inches - wholeInches) * 16).toFixed(0);
      let numerator = parseInt(fractionalInches, 10);
      let denominator = 16;

      // Validate numerator and denominator
      if (isNaN(numerator) || isNaN(denominator)) {
        return "";
      }

      const divisor = gcd(numerator, denominator);
      numerator /= divisor;
      denominator /= divisor;

      if (numerator >= denominator) {
        wholeInches += Math.floor(numerator / denominator);
        numerator %= denominator;
      }

      let result = `${feet}' ${wholeInches}`;

      if (numerator > 0) {
        result += ` ${numerator}/${denominator}"`;
      } else {
        result += '"';
      }

      return result;
    }
  };

  useEffect(() => {
    const initialTangents = pitchAngles.map((angle) => calculateTangent(angle));
    setTangents(initialTangents);
  }, [pitchAngles]); // Include pitchAngles in the dependency array

  const handlePitchAngleChange = (index, value) => {
    const newPitchAngles = [...pitchAngles];
    newPitchAngles[index] = value;
    setPitchAngles(newPitchAngles);

    const newTangents = newPitchAngles.map((angle) => calculateTangent(angle));
    setTangents(newTangents);
  };

  const handleFflFflInMMChange = (event) => {
    const value = event.target.value;
    setFflFflInMM(value);
  };

  const handleMinGoingChange = (event) => {
    const value = event.target.value;
    setMinGoing(value);
    calculateGap(value, spindleSize);
  };

  const handleSpindleSizeChange = (event) => {
    const value = event.target.value;
    setSpindleSize(value);
    calculateMaxGoing2Spindles(value);
    calculateGap(minGoing, value);
  };

  const calculateMaxGoing2Spindles = (spindleSize) => {
    if (spindleSize === "") {
      setMaxGoing("");
    } else {
      const maxGoingValue = 200 + spindleSize * 2;
      setMaxGoing(maxGoingValue);
      calculateMaxGap(maxGoingValue, spindleSize);
    }
  };

  const calculateMaxGap = (maxGoing, spindleSize) => {
    if (maxGoing === "" || spindleSize === "") {
      setMaxGap("");
    } else {
      const maxGapValue = ((maxGoing - spindleSize * 2) / 2).toFixed(0);
      setMaxGap(maxGapValue);
    }
  };

  const calculateGap = (minGoing, spindleSize) => {
    if (minGoing === "" || spindleSize === "") {
      setGap("");
    } else {
      const gapValue = (
        (parseInt(minGoing) - parseInt(spindleSize) * 2) /
        2
      ).toFixed(0);
      setGap(gapValue);
    }
  };

  // Function to calculate number of treads
  const calculateTreads = (going, minGoing, treadCount) => {
    if (going === "" || minGoing === "") return "";
    return Math.round(going / minGoing) + parseInt(treadCount);
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: 4 }}>
      <Paper elevation={3} sx={{ padding: 2, borderRadius: 8 }}>
        <Typography
          gutterBottom
          sx={{ fontWeight: "bold", fontSize: "16px", marginTop: 4 }}
        >
          ffl-ffl in mm
        </Typography>
        <TextField
          label=""
          variant="outlined"
          fullWidth
          margin="normal"
          sx={{ backgroundColor: "#f2bfc1" }}
          type="number"
          value={fflFflInMM}
          onChange={handleFflFflInMMChange}
          InputProps={{
            inputProps: { min: 0 },
            style: { height: "40px", padding: "8px" }, // Adjust padding as needed
          }}
          InputLabelProps={{
            style: { lineHeight: "40px" }, // Adjust line-height to vertically center the label text
          }}
        />
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Pitch angle º</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>tan</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Going (mm)</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Going (ft/inches)
                </TableCell>
              </TableRow>
              {pitchAngles.map((angle, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      backgroundColor: "#90EE90",
                    }}
                  >
                    <TextField
                      value={angle}
                      variant="outlined"
                      sx={{
                        "& fieldset": { border: "none" },
                        width: "100px",
                      }}
                      type="number"
                      fullWidth
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={(e) =>
                        handlePitchAngleChange(index, e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell
                    sx={{ paddingTop: 0, paddingBottom: 0, fontWeight: "bold" }}
                  >
                    {tangents[index]}
                  </TableCell>
                  <TableCell
                    sx={{ paddingTop: 0, paddingBottom: 0, fontWeight: "bold" }}
                  >
                    {calculateGoingMM(fflFflInMM, tangents[index])}
                  </TableCell>
                  <TableCell
                    sx={{ paddingTop: 0, paddingBottom: 0, fontWeight: "bold" }}
                  >
                    {convertToFeetInches(
                      calculateGoingMM(fflFflInMM, tangents[index])
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography
          gutterBottom
          sx={{ fontWeight: "bold", fontSize: "16px", marginTop: 4 }}
        >
          Spindle Size
        </Typography>
        <TextField
          label=""
          sx={{ backgroundColor: "#f2bfc1" }}
          variant="outlined"
          fullWidth
          margin="normal"
          type="number"
          value={spindleSize}
          onChange={handleSpindleSizeChange}
          InputProps={{
            inputProps: { min: 0 },
            style: { height: "40px", padding: "8px" }, // Adjust padding as needed
          }}
          InputLabelProps={{
            style: { lineHeight: "40px" }, // Adjust line-height to vertically center the label text
          }}
        />

        <TableContainer component={Paper} sx={{ marginTop: 4 }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Min Going</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Gap</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Max Going 2 Spindles
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Max Gap</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TextField
                    value={minGoing}
                    variant="outlined"
                    sx={{
                      "& fieldset": { border: "none" },
                      width: "100px",
                    }}
                    type="number"
                    fullWidth
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleMinGoingChange}
                  />
                </TableCell>
                <TableCell>{gap}</TableCell>
                <TableCell>{maxGoing}</TableCell>
                <TableCell>{maxGap}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography
          gutterBottom
          sx={{ fontWeight: "bold", fontSize: "16px", marginTop: 4 }}
        >
          Tread Count (+ or -)
        </Typography>
        <Select
          sx={{
            marginTop: 2,
            backgroundColor: "#90EE90",
            height: "40px"
          }}
          value={selectedOption}
          onChange={handleOptionChange}
          variant="outlined"
          fullWidth
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Pitch angle º</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  No. of Treads (Min Going)
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  No. of Treads (Max Going)
                </TableCell>
              </TableRow>
              {pitchAngles.map((angle, index) => (
                <TableRow key={index}>
                  <TableCell>{angle}</TableCell>
                  <TableCell>
                    {calculateTreads(
                      calculateGoingMM(fflFflInMM, tangents[index]),
                      minGoing,
                      selectedOption
                    )}
                  </TableCell>
                  <TableCell>
                    {calculateTreads(
                      calculateGoingMM(fflFflInMM, tangents[index]),
                      maxGoing,
                      selectedOption
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer sx={{ marginTop: 5 }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>
                  No. of Treads (Max Going)
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Pitch angle º</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Rise (mm)</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Rise (ft/inches)
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Going (mm)</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Going (ft/inches)
                </TableCell>
              </TableRow>
              {pitchAngles.map((angle, index) => {
                const noOfTreads = calculateTreads(
                  calculateGoingMM(fflFflInMM, tangents[index]),
                  maxGoing,
                  selectedOption
                );
                const riseMM = calculateRiseMM(fflFflInMM, noOfTreads);
                const goingMM = calculateGoingMM(fflFflInMM, tangents[index]);
                const goingInMM = calculateGoingInMM(goingMM, noOfTreads);
                return (
                  <TableRow key={index}>
                    <TableCell>{noOfTreads}</TableCell>
                    <TableCell>{angle}</TableCell>
                    <TableCell>{riseMM}</TableCell>
                    <TableCell>{convertToFeetInches(riseMM)}</TableCell>
                    <TableCell>{goingInMM}</TableCell>
                    <TableCell>{convertToFeetInches(goingInMM)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default Home;
