import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation } from 'react-router-dom';

const TopBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation(); // Hook to get current route location

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerList = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={Link} to="/" selected={location.pathname === '/'}>
          <ListItemText primary="Stair Going Calculator" />
        </ListItem>
        <ListItem button component={Link} to="/imperial-calculator" selected={location.pathname === '/imperial-calculator'}>
          <ListItemText primary="Imperial Calculator" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: 'black' }}>
      <Toolbar>
        <Box display="flex" alignItems="center" width="100%">
          <img src="/logo.jpg" alt="Logo" style={{ height: 40, marginLeft: 40, marginRight: 10 }} />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            Knostairs
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Button color="inherit" component={Link} to="/" sx={{ color: location.pathname === '/' ? 'grey' : 'inherit' }}>
              Stair Going Calculator
            </Button>
            <Button color="inherit" component={Link} to="/imperial-calculator" sx={{ color: location.pathname === '/imperial-calculator' ? 'grey' : 'inherit' }}>
              Imperial Calculator
            </Button>
          </Box>
        </Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={toggleDrawer(true)}
          sx={{ display: { xs: 'block', sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList}
      </Drawer>
    </AppBar>
  );
};

export default TopBar;
