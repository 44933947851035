import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopBar from './components/TopBar';
import Home from './pages/Home';
import ImperialCalculator from './pages/ImperialCalculator';
import Preloader from './components/Preloader';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay (e.g., fetching data, etc.)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay time as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <TopBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/imperial-calculator" element={<ImperialCalculator />} />
          </Routes>
        </>
      )}
    </Router>
  );
};

export default App;
